.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #eeeff2;
  width: 100%;


  h1 {
    margin: 0;
  }

  &-content {
    display: flex;
    width: 100%;
    max-width: 1340px;
    padding: 3rem 2rem;

    &__left {
      flex: 1;

      img {
        &.logo{
          width: 6rem;
        }
      }
    }

    &__contact-us-content {
      max-width: 26rem;

      a {
        color: -webkit-link;
        text-decoration: underline;
      }

      li {
        display: flex;
        gap: 0.5rem;
        margin: 0.75rem 0;
      }
    }



    &__download {
      margin-top: 4rem;
    }

    &__download-content {
      display: flex;
      gap: 0.5rem;
      margin: 0.5rem 0 1.25rem;
    }


    &__follow-us {
      margin-top: 2rem;
    }

    &__follow-us-content {
      display: flex;
      gap: 0.5rem;
      margin: 0.5rem 0 1.25rem;
    }

    &__other-links-content {
      display: flex;
      gap: 5rem;


      &-item {
        p {
          color: #202325;
          opacity: .6;
        }

        li {
          list-style-type: none;
          margin: 1rem 0;

          a {
            color: black;

            &:hover {
              color: #ee4d2d;
            }
          }
        }
      }
    }


    @media screen and (max-width: 767px) {
      flex-direction: column;

      &__other-links-content {
        display: none;
      }
    }
  }

  &-copyright {
    border-top: 1px solid #eeeff2;
    width: 100%;
    text-align: center;
    max-width: 1340px;

    p {
      margin: 2rem 0;
      color: rgba(32, 35, 37, 0.35);

      a {
        color: rgba(32, 35, 37, 0.35);

        &:hover {
          color: #ee4d2d;
        }
      }
    }
  }
}
@primary-color: rgb(238, 77, 45);